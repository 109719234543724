import {Routes} from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./sections/welcome/welcome.routes').then(m => m.welcomeRoutes)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./sections/sobre-nosotros/sobre-nosotros.routes').then(m => m.sobreNosotrosRoutes),
  },
  {
    path: 'services',
    loadChildren: () => import('./sections/servicios/general/general.routes').then(m => m.generalRoutes)
  },
  {
    path: 'digital-consulting',
    loadChildren: () => import('./sections/servicios/digital-consulting/digital-consulting.routes').then(m => m.digitalConsultingRoutes)
  },
  {
    path: 'customer-engagement',
    loadChildren: () => import('./sections/servicios/customer-engagement/customer-engagement.routes').then(m => m.customerEngagementRoutes)
  },
  {
    path: 'data-analytics-ai',
    loadChildren: () => import('./sections/servicios/data-analytics-ai/data-analytics-ai.routes').then(m => m.dataAnalyticsAiRoutes)
  },
  {
    path: 'technology-architecture',
    loadChildren: () => import('./sections/servicios/technology-architecture/technology-architecture.routes').then(m => m.technologyArchitectureRoutes)
  },
  {
    path: 'contacto',
    loadChildren: () => import('./sections/contacto/contacto.routes').then(m => m.contactoRoutes)
  },
  {
    path: 'join-us-form',
    loadChildren: () => import('./sections/join-us-form/join-us-form.routes').then(m => m.joinUsFormRoutes)
  },
  {
    path: 'stack-tecnologico',
    loadChildren: () => import('./sections/stack-tecnologico/stack-tecnologico.routes').then(m => m.stackTecnologicoRoutes)
  },
  {
    path: 'terms-of-use',
    loadChildren: () => import('./sections/terms-of-use/terms-of-use.routes').then(m => m.termsOfUseRoutes)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./sections/privacy-policy/privacy-policy.routes').then(m => m.privacyPolicyRoutes)
  },
  {
    path: 'manifiesto',
    loadChildren: () => import('./sections/sobre-nosotros/manifiesto/manifiesto.routes').then(m => m.manifiestoRoutes)
  },
  {
    path: 'join-us',
    loadChildren: () => import('./sections/join-us/join-us.routes').then(m => m.joinUsRoutes)
  },
  {
    path: '**',
    loadChildren: () => import('./sections/welcome/welcome.routes').then(m => m.welcomeRoutes)
  },
];
