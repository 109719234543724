import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {provideRouter, withHashLocation} from '@angular/router';
import {initializeApp, provideFirebaseApp,} from '@angular/fire/app';

import {routes} from './app.routes';
import {provideClientHydration} from '@angular/platform-browser';

import {getFirestore, provideFirestore} from "@angular/fire/firestore";
import {getStorage, provideStorage} from "@angular/fire/storage";
import {environment} from "../environments/environment";
import {provideNoopAnimations} from "@angular/platform-browser/animations";

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom([
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      provideFirestore(() => getFirestore()),
      provideStorage(() => getStorage()),
    ]),
    provideNoopAnimations(),
    provideRouter(routes, withHashLocation()),
    provideClientHydration(),
  ]
};


