import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet} from '@angular/router';
import {MatIcon, MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {CustomIcon} from "./common/custom-icon";
import {MatSidenav, MatSidenavContainer, MatSidenavContent} from "@angular/material/sidenav";
import {MatIconButton} from "@angular/material/button";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatToolbar} from "@angular/material/toolbar";
import {FooterComponent} from "./sections/footer/footer.component";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: 'et-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    MatSidenavContainer,
    MatSidenavContent,
    MatSidenav,
    MatIcon,
    MatIconButton,
    RouterLinkActive,
    RouterLink,
    FlexLayoutModule,
    MatToolbar,
    RouterOutlet,
    FooterComponent,
    MatProgressSpinner
  ],
})
export class AppComponent implements OnInit {
  location: any;
  logo: String = 'logo.png';

  constructor(private router: Router, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconLiteral('close', sanitizer.bypassSecurityTrustHtml(CustomIcon.CLOSE));
    iconRegistry.addSvgIconLiteral('menu', sanitizer.bypassSecurityTrustHtml(CustomIcon.MENU));
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.location = this.router.url;
        if (this.isLight()) {
          this.logo = 'logo2.png';
        } else {
          this.logo = 'logo.png';
        }
      }
    })
  }

  isLight() {
    return this.location == undefined || this.location == '/' || this.location == '/about-us'
      || this.location == '/digital-consulting'
      || this.location == '/customer-engagement'
      || this.location == '/data-analytics-ai'
      || this.location == '/technology-architecture'
      || this.location == '/stack-tecnologico'
      || this.location == '/contacto'
      || this.location == '/join-us';
  }
}
