<mat-sidenav-container hasBackdrop="false">
  <mat-sidenav #sidenav [mode]="'push'" position="end">
    <div class="sidenav">
      <div>
        <button (click)="sidenav.toggle()" aria-label="Menu" mat-icon-button>
          <mat-icon aria-label="close" class="icon active"
                    svgIcon="close"></mat-icon>
        </button>
      </div>
      <div style="height: 20px"></div>
      <ul>
        <li>
          <a (click)="sidenav.toggle()" [routerLinkActiveOptions]="{exact: true}" class="nav-link"
             routerLink="/about-us" routerLinkActive="active">Sobre nosotros</a>
        </li>
        <li>
          <a (click)="sidenav.toggle()" class="nav-link" routerLink="/services" routerLinkActive="active">Servicios</a>
        </li>
        <li>
          <a (click)="sidenav.toggle()" class="nav-link" routerLink="/stack-tecnologico" routerLinkActive="active">Stack
            Tecnológico</a>
        </li>
        <li>
          <a (click)="sidenav.toggle()" class="nav-link" routerLink="/contacto" routerLinkActive="active">Contacto</a>
        </li>
        <li>
          <a (click)="sidenav.toggle()" class="nav-link" routerLink="/join-us-form" routerLinkActive="active">Únete a
            eTribe</a>
        </li>
        <!--li>
          <a (click)="sidenav.toggle()" class="nav-link" routerLink="/join-us" routerLinkActive="active">La tribu</a>
        </li-->
      </ul>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <header [class]="isLight() ? 'light' : 'dark'" class="header">
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-toolbar [class]="isLight() ? 'light' : 'dark'" class="content-size">
          <div fxFlex="50" fxLayoutAlign="start center">
            <img alt="logo" class="logo padding-toolbar" routerLink="/" src="assets/img/{{logo}}">
          </div>
          <div class="padding-toolbar" fxFlex="50" fxLayoutAlign="end center">
            <button (click)="sidenav.toggle()" aria-label="Menu" mat-icon-button>
              <mat-icon [class]="isLight() ? 'light' : 'dark'" class="icon" svgIcon="menu"></mat-icon>
            </button>
          </div>
        </mat-toolbar>
      </div>
    </header>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
@defer (on viewport) {
  <et-footer></et-footer>
} @placeholder {
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
  </div>
}

