export const environment = { 
    firebase: {
    apiKey: "AIzaSyCJHdVV4yjjRsCAVZpmEy_rU4KJUHF3pZQ",
    authDomain: "landing-page-etribe.firebaseapp.com",
    projectId: "landing-page-etribe",
    storageBucket: "landing-page-etribe.appspot.com",
    messagingSenderId: "737352359987",
    appId: "1:737352359987:web:e49bb7e42d122e565c0346",
    measurementId: "G-WCYD3J4LTR",
    databaseURL: 'https://landing-page-etribe-default-rtdb.firebaseio.com',
  },
  production: false
};